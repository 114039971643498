<template>
    <button
        :style="{'background-color': backgroundColor, 'border-color': borderColor, 'color': color}"
        :class="classes"
        :disabled="disabled"
        @click="onClick"
    >
        <div class="d-inline ml-6" v-if="label.toLowerCase() === 'ถัดไป' || label.toLowerCase() === 'เสร็จสิ้น' || label.toLowerCase() === 'แชร์เลย!'">
            {{ label }}
        </div>
        <div class="d-inline mr-6" v-else>
            {{ label }}
        </div>
        <v-icon v-if="label.toLowerCase() === 'ถัดไป' || label.toLowerCase() === 'เสร็จสิ้น' || label.toLowerCase() === 'แชร์เลย!'" class="float-right mt-1" color="white">
            mdi-play
        </v-icon>
        <v-icon v-else-if="label.toLowerCase() === 'เลือกการ์ดใหม่' || label.toLowerCase() === 'เปลี่ยนรูปภาพ' || label.toLowerCase() === 'แก้ไข'" class="float-left mt-1" color="black"> mdi-play mdi-rotate-180</v-icon>
        <div v-else></div>
    </button>
</template>

<script>
export default {
    name: 'base-button',
    props: {
        label: {
            type: String,
            required: true,
        },
        backgroundColor: {
            type: String,
            default: '#fff',
        },
        borderColor: {
            type: String,
            default: 'black',
        },
        color: {
            type: String,
            default: '#000',
        },
        outline: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        shadow: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                'button': true,
                'button-outline': this.outline,
                'button-shadow': this.shadow,
            };
        },
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        },
    },
}
</script>

<style scoped>
.button {
    /* font-weight: 700; */
    font-size: 20px !important;
    font-family: Noto Sans Thai !important;
    border: 2px solid #453630;
    /* border-radius: 24px; */
    color: #ffffff;
    /* background-color: #453630; */
    height: 38px !important;
    min-height: 38px !important;
    padding: 0 14px !important;
    letter-spacing: 0;
    text-indent: 0;
    text-transform: none;
    flex: 1;
}
.button:focus {
    box-shadow: none;
    outline: none
}
.button:disabled {
    background-color: #CCD0D1 !important;
    border: 2px solid #CCD0D1 !important;
    color: grey !important;
}
.button-outline {
    border: 2px solid #453630 !important;
    background-color: #ffffff !important;
    color: #222222 !important;
}
.button.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #CCD0D1 !important;
    border: 2px solid #CCD0D1 !important;
    color: #ffffff !important;
}
.button.button-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
}
</style>
