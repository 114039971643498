<template>
    <div :class="classes">
        <slot></slot>
    </div>
</template>

<script>
export default {
    computed: {
        classes() {
            return {
                'content': true,
            };
        },
    },
}
</script>

<style scoped>
</style>
