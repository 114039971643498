import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/step-1',
    name: 'Step 1 Page',
    component: () => import('../views/card/Step-1.vue')
  },
  {
    path: '/step-2',
    name: 'Step 2 Page',
    component: () => import('../views/card/Step-2.vue')
  },
  {
    path: '/step-2-upload',
    name: 'Upload 2 Page',
    component: () => import('../views/card/Upload.vue')
  },
  {
    path: '/success',
    name: 'Success Page',
    component: () => import('../views/card/Success.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
